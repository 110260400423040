<template>
  <div class="middle-common explore-meta">
    <h5 class="explore-title">探索元宇宙</h5>
    <p class="explore-txt">
      加入数以千计的用户，在玩家创造元宇宙空间中自由探索
    </p>
    <div class="explore-box">
      <div class="explore-item" v-for="(item,index) in list" :key="item.id"  @click="joinRoom(item.hub_sid)">
        <img v-lazy="`https://app.metaio.cc/files/${item.scene.screenshot_owned_file.owned_file_uuid}`" class="explore-bcg" style="height1: 174px" v-if="index < 2"/>
        <img v-lazy="`https://app.metaio.cc/files/${item.scene.screenshot_owned_file.owned_file_uuid}`" class="explore-bcg" style="height1: 115px" v-if="index >= 2"/>
          <h5 class="leftBtm headline">{{ item.title }}</h5>
          <p class="leftBtm txt" :title="item.introduction">{{ item.introduction }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getRoomHotList } from '@/api/api'
import { getToken } from '@/utils/auth'
export default {
  name: "exploreMeta",
  methods: {
    getList() {
      const params = {
        type: this.type,
        page: this.page,
        paginate: this.paginate
      }
      getRoomHotList(params).then(res => {
        if (res.code == 200) {
          this.list = res.data.data
        }

      })
    },
    joinRoom(sid) {
      // if (!getToken()) {
      //   return this.$router.push({ path: '/login'})
      // }
      this.$router.push({ path: `/metaScene?sid=${sid}`})
      // window.open(`https://app.metaio.cc/${sid}?token=${getToken()}`);
    },
  },
  data() {
    return {
      list: [
      ]
    }
  },
  mounted() {
    this.$nextTick().then(res => {
      const query = this.$route.query
      this.page = query.page ? query.page - 0 : 1
      this.type = query.type ? query.type : 1
      this.getList()
    })
  }
}
</script>

<style scoped lang="scss">
.explore-meta {
  .explore-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    &:after {
      width:calc((100% - 63px) / 3);
      content: "";
    }
    .explore-item {
      width: calc((100% - 63px) / 3);
      height: 242px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      text-align: left;
      margin-bottom: 22px;
      border-radius: 12px;
      &:nth-child(1),
      &:nth-child(2) {
        width: 37.3vw;
        height: 365px;
      }

      .explore-bcg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 12px;
      }

      .leftBtm {
        position: absolute;
        left: 47px;
        bottom: 34px;/*no*/
        font-size: 14px;
        text-shadow: 0px 4px 4px #000000;
        width: 382px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.headline {
          bottom: 58px;/*no*/
          text-align: left;
          text-shadow: 4px 4px 4px #000000;
          font-weight: 700;
          font-size: 64px;
        }
      }
    }
  }
}
</style>
