<template>
  <div class="middle-common introduce">
    <div class="introduce-title">
      <img class="introduce-logo" src="../../../assets/images/logo2.png" />是国内首个元宇宙场景构建平台。元宇引擎可以辅助一位非专业工作者短时间内搭建出高质量企业定制化元宇宙场景，实现低成本、高质量、高效率的元宇宙场景构建。
    </div>
    <div class="introduce-content">
      <div class="introduce-content-item">
        <div class="introduce-content-item-title">
          <span class="introduce-content-item-title-txt">强大的元应用生态 满足多种业务需求</span>
          <div class="introduce-content-item-title-bg" />
        </div>
        <div class="introduce-content-item-content">
          借助丰富的元应用生态，用户可以任意挑选应用功能运用到场景之中，实现商务会展、沙龙、路演、艺术品展示、营销购物、线上教学、娱乐活动等多种业务需求。
        </div>
      </div>
      <div class="introduce-content-item">
        <div class="introduce-content-item-title">
          <span class="introduce-content-item-title-txt">傻瓜式搭建场景 无需专业能力</span>
          <div class="introduce-content-item-title-bg" />
        </div>
        <div class="introduce-content-item-content">
          傻瓜式操作，创作者无需专业技能，短时间内可以搭建出企业级别元宇宙场景。实时光影渲染、自然环境模拟、数万贴图和建模素材等功能辅助您高效建模并一键上传，生成高质量元宇宙场景。
        </div>
      </div>
      <div class="introduce-content-item">
        <div class="introduce-content-item-title">
          <span class="introduce-content-item-title-txt">构建场景价格低至万元</span>
          <div class="introduce-content-item-title-bg" />
        </div>
        <div class="introduce-content-item-content">
          多种服务模式，低成本打造定制化元宇宙场景，轻松举办一切线上活动。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRoomHotList } from '@/api/api'
import { getToken } from '@/utils/auth'
export default {
  name: "exploreMeta",
  methods: {
    getList() {
      const params = {
        type: this.type,
        page: this.page,
        paginate: this.paginate
      }
      getRoomHotList(params).then(res => {
        if (res.code == 200) {
          this.list = res.data.data
        }

      })
    },
    joinRoom(sid) {
      if (!getToken()) {
        return this.$router.push({ path: '/login'})
      }
      this.$router.push({ path: `/metaScene?sid=${sid}`})
      // window.open(`https://app.metaio.cc/${sid}?token=${getToken()}`);
    },
  },
  data() {
    return {
      list: [
      ]
    }
  },
  mounted() {
    this.$nextTick().then(res => {
      const query = this.$route.query
      this.page = query.page ? query.page - 0 : 1
      this.type = query.type ? query.type : 1
      this.getList()
    })
  }
}
</script>

<style scoped lang="scss">
.introduce{
  position: relative;
  left: 30px;
  .introduce-title {
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    color: #000;
    text-align: left;
    line-height: 70px;
    .introduce-logo {
      width: 400px;
      position: relative;
      top: -24px;
      text-shadow: 8px 10px 10px #656B79;
      padding-right: 10px;
    }
  }
  .introduce-content {
    margin-top: 30px;
    display: flex;
    color: #000;
    text-align: left;
    position: relative;
    left: 30px;
    .introduce-content-item {
      flex: 1;
      padding: 0 40px;
      .introduce-content-item-title {
        margin-top: 104px;
        font-family: 'Microsoft YaHei';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 20px;
        position: relative;
        .introduce-content-item-title-txt {
          position: relative;
          z-index: 2;
        }
        .introduce-content-item-title-bg {
          width: 115px;
          height: 115px;
          border-radius: 1000px;
          background: linear-gradient(180deg, #E6E6E6 0%, rgba(230, 230, 230, 0) 100%);
          position: absolute;
          left: -50px;
          top: -50px;
        }
      }
      .introduce-content-item-content {
        font-family: 'Microsoft YaHei';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 42px;
      }
    }
  }
}
</style>

