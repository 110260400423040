<template>
  <div class="middle-common event-notice">
    <h5 class="explore-title">活动公告</h5>
    <div class="swiper-box">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="item in noticeSwiper" :key="item.id">
          <div class="swiperCon">
          <div class="swiper-left">
            <p class="tips">{{ item.tips }}</p>
            <h5>{{ item.title }}</h5>
            <div class="content">{{ item.content }}</div>
          </div>
          <div class="swiper-right">
            <img v-lazy="item.img" alt="活动图片" class="eventImg" />
          </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "eventNotice",
  components: {
    swiper,
    swiperSlide,
  },
  methods: {},
  data() {
    return {
      noticeSwiper: [
        {
          id: 1,
          tips: "META I/O项目更新",
          title: "Faraday Future",
          content: "",
          img: require("../../../assets/images/swiper.png"),
        },
        {
          id: 2,
          tips: "META I/O项目更新",
          title: "Faraday Future",
          content:
            "META I/O为乐视旗下汽车品牌Faraday Future打造的元宇宙社交场景将于5月初正式上线，届时将举办线上沙龙等活动，请持续关注！",
          img: require("../../../assets/images/swiper.png"),
        },
      ],
      swiperOption: {
        loop: false,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    console.log("this is current swiper instance object", this.swiper);
    // this.swiper.slideTo(3, 1000, false);
  },
};
</script>

<style scoped lang="scss">
.middle-common.event-notice {
    margin-bottom: 380px;
  .swiper-box {
    height: 500px;
    width: 100%;
    .swiper-container {
      height: 120%;
      width: 100%;
      .swiper-slide {
        display: flex;
         width: 100%;
          height: 100%;
        align-items: center;
        .swiperCon{
          display: flex;
          align-items: center;
          // height: 400px;
          box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
          position: absolute;
          left: 70px;
          border-radius: 1rem;
          .swiper-left {
          color: #000;
          width: 429px;
          padding: 82.8px 61.2px 92.4px;
          box-sizing: border-box;
          text-align: left;
          .tips {
            font-weight: 700;
            font-size: 18px;
            color: #8d8d8d;
          }
          h5 {
            height: 61px;
            line-height: 61px;
            font-weight: 700;
            font-size: 38.4px;
            margin-bottom: 12px;
          }
        }
        .swiper-right {
          width: 913px;
          height: 100%;
          .eventImg {
            width: 100%;
            height: 100%;
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;
          }
        }
        }
      }
      .swiper-pagination {
        ::v-deep .swiper-pagination-bullet {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          &.swiper-pagination-bullet-active {
            background: #000000!important;
          }
        }
      }
      ::v-deep .swiper-button-prev {
        background-image: url('../../../assets/images/Vector_left.png');
        left: 0;
      }
      ::v-deep .swiper-button-next {
        background-image: url('../../../assets/images/Vector_right.png');
        right: 0;
      }
    }
    .swiper-container-horizontal > .swiper-pagination-bullets{
      bottom: 0px;/*no*/
    }
  }
}
</style>
