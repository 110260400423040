<template>
  <div class="home">
    <!-- 头部 -->
    <t-header />
    <!-- 中间部分 -->
    <home-middle />
    <!-- 底部 -->
    <t-footer />
  </div>
</template>

<script>
import tFooter from '@/components/tFooter/index.vue'
import tHeader from '@/components/tHeader/index.vue'
import homeMiddle from '@/components/homeMiddle/index.vue'
export default {
  name: 'home',
  components: {
    tHeader,
    homeMiddle,
    tFooter
  },
  data () {
    return {
    }
  }
}
</script>
