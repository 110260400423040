<template>
  <div class="home-middle">
    <!-- 开始探索 -->
    <div class="start-search" :style="{backgroundImage: 'url('+home+')'}">
      <div class="search-mask"></div>
      <div class="search-box">
        <img v-lazy="logo" class="logo" alt="logo" />
        <p class="search-txt">借助0代码的编辑工具，成为自己元宇宙的创造者</p>
        <div class="search-btn" @click="jumpPage('square')">开始探索</div>
      </div>
    </div>
    <!-- 介绍 -->
    <introduce />
    <!-- 探索元宇宙 -->
    <explore-meta />
    <!-- 活动公告 -->
    <!-- <event-notice /> -->
    <!-- 让任何创意成为可能 -->
    <div class="middle-common idea-become">
      <img src="../../assets/images/idea.png" class="idea-logo" alt="">
      <p class="idea-txt">让任何创意成为可能</p>
      <img src="../../assets/images/chuangyi.png" class="idea-img" alt="">
    </div>
    <!-- 创作者是元宇宙的核心 -->
    <div class="middle-common idea-become core-box">
      <h5>创作者是元宇宙的核心</h5>
      <p class="idea-txt">让任何创意成为可能</p>
      <img src="../../assets/images/core.png" class="idea-img" alt="">
    </div>
    <!-- 免费使用META I/O -->
    <div class="middle-common idea-become core-box free-use">
      <h5>免费使用META I/O </h5>
      <p class="idea-txt">并支持从任何终端进入</p>
      <div class="web-portal"  @click="jumpPage('square')">网页入口</div>
      <span class="or">或</span>
      <ul>
        <li>
          <img src="../../assets/images/entry1.png" alt="">
        </li>
        <li>
          <img src="../../assets/images/entry2.png" alt="">
        </li>
        <li>
          <img src="../../assets/images/entry3.png" alt="">
        </li>
        <li>
          <img src="../../assets/images/entry4.png" alt="">
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import eventNotice from '@/components/homeMiddle/components/eventNotice'
import exploreMeta from '@/components/homeMiddle/components/exploreMeta'
import introduce from '@/components/homeMiddle/components/introduce'
export default {
  name: "homeMiddle",
  components: {
    eventNotice,
    exploreMeta,
    introduce
  },
  methods: {},
  data() {
    return {
      logo: require("../../assets/images/logo_1.png"),
      home: require("../../assets/images/home.png")
    }
  },
  created() {},
  methods: {
    jumpPage(text) {
      this.$router.push({ path: text })
    }
  }
};

</script>
<style scoped lang="scss">
@import '../../assets/css/homeMiddle.scss';

</style>
